import React from 'react';

const RedeemModal = (props) => {
    return (
        <div className={`modal fade ${props.show && 'show d-block bg-darkened'}`}>
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div className="modal-content shadow-dark-80">
                    <div className="modal-header border-0 pb-0 justify-content-center align-items-center ps-4 mb-2">
                        <h5 className="modal-title pt-3" id="exampleModalLabel" dangerouslySetInnerHTML={{ __html: props.promo.description_title }}></h5>
                    </div>
                    <div className="modal-body pt-2 px-4 justify-content-center text-center">
                        {
                            props.promo.code_type === "no_code" ? (
                                <p>{props.promo.more_info || 'Accedé a este beneficio en los locales o en el e-shop!'}</p>
                            ) : (
                                <>
                                    <p className="fw-bold">Vas a canjear {props.promo.points} puntos.</p>
                                    <p>Si continúas te entregaremos el código promocional y se restarán los puntos de tu cuenta.</p>
                                </>
                            )
                        }
                    </div>
                    <div className="modal-footer row">
                        {
                            props.promo.code_type === 'no_code' && (props.promo.ecommerce_url ? (
                                <a className="btn btn-link mb-2" href={props.promo.ecommerce_url} target="_blank" rel="noreferrer">Ir a la web</a>
                            ) : (
                                <button type="button" className="btn btn-link mb-2" onClick={() => props.setShow(false)}><span className="px-1">Cerrar</span></button>
                            ))
                        }
                        {
                            props.promo.code_type !== 'no_code' && (
                                <button type="button" className="btn btn-link mb-2" onClick={() => props.redeem()}><span className="px-1">Continuar</span></button>
                            )
                        }
                    </div>
                    {
                        props.promo.code_type !== 'no_code' && (
                            <div className="modal-footer row">
                                <button type="button" className="btn btn-link text-danger mb-2" onClick={() => props.setShow(false)}><span className="px-1">Cancelar</span></button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default RedeemModal;
