import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

const AlreadyRedeemedModal = (props) => {
    const [latest, setLatest] = useState('');

    useEffect(() => {
        const searchLatestRedeem = () => {
          if (!props.redeem || !props.redeem.redeems) {
            return false;
          }
    
          let l = props.redeem.redeems[0].createdAt;
    
          props.redeem.redeems.forEach((r) => {
            if (r.createdAt > latest) {
              l = r.createdAt;
            }
          });
          setLatest(new Date(l).toLocaleDateString('es-ar'));
        };
        searchLatestRedeem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.redeem]);

    return (
        <div className={`modal fade ${props.show && 'show d-block bg-darkened'}`}>
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div className="modal-content shadow-dark-80">
                    <div className="modal-header border-0 pb-0 justify-content-center align-items-center ps-4 mb-2">
                        <h5 className="modal-title pt-3" id="exampleModalLabel">Atención</h5>
                    </div>
                    <div className="modal-body pt-2 px-4 justify-content-center text-center">
                        <p>Ya canjeaste esta promoción {props.redeem.canRedeem && props.redeem.redeems.length > 1 ? ` ${props.redeem.redeems.length} veces. El último canje fue el ${latest}.` : ` el ${latest}.`}</p>
                    </div>
                    {
                        props.redeem.canRedeem && (
                            <div className="modal-footer row">
                                <button type="button" className="btn btn-link mb-2" onClick={() => props.confirm(true)}><span className="px-1">Canjear de nuevo</span></button>
                            </div>
                        )
                    }
                    <div className="modal-footer row">
                        <button type="button" className="btn btn-link mb-2" onClick={() => props.history.push('/mi-cuenta/canjes')}><span className="px-1 fw-bold">Ir a Mis Canjes</span></button>
                    </div>
                    <div className="modal-footer row">
                        <button type="button" className="btn btn-link text-danger mb-2" onClick={() => props.setShow(false)}><span className="px-1">Cancelar</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(AlreadyRedeemedModal);
