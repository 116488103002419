/* DEPENDENCIES */
import './styles/App.scss';
import React, { useEffect, useState } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import OneSignal from 'react-onesignal';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import Loader from './components/Loader';
/* SCREENS */
import Login from './screens/Login';
import SignUp from './screens/SignUp';
import ResetPassword from './screens/ResetPassword';
import VerifyEmail from './screens/VerifyEmail';
import VerifyNationalIdentity from './screens/VerifyNationalIdentity';
import Profile from './screens/Profile';
import Promos from './screens/Promos';
import PromoDetail from './screens/PromoDetail';
import MyAccount from './screens/MyAccount';
import Terms from './screens/Terms';
/* SERVICE */
import {getAssets} from './services/assets';
/* ERROR SCREENS */
import NotFound from './screens/404';
/* STORE */
import Store from './reducers';

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
  const [redirect] = useState(window.location.pathname + window.location.search);
  const [OSInitiated, setOSInitiated] = useState(false);
  const [notification, setNotification] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const unprotected = ['/ingresa', '/registrate', '/recuperar-contrasena', '/terminos-y-condiciones'];

  useEffect(() => {
    console.log('VERSION', process.env.REACT_APP_VERSION);

    // GET ASSETS
    getAssets();

    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
    });
  
    return function cleanup() {
      login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const startWebNotification = async () => {
      await OneSignal.init({ appId: '858e763b-b6aa-4952-ab75-b9c4234a6b8b', promptOptions: {
        actionMessage: 'Recibí novedades sobre tu cuenta de EQS Club',
        acceptButton: 'Aceptar',
        acceptButtonText: 'Aceptar',
        cancelButton: 'Ahora no',
        cancelButtonText: 'Ahora no',
        showCredit: false,
        exampleNotificationTitleDesktop: 'Tus puntos están por vencer',
        exampleNotificationMessageDesktop: 'Accedé a un descuento especial antes de su vencimiento!',
        exampleNotificationTitleMobile: 'Tus puntos están por vencer',
        exampleNotificationMessageMobile: 'Accedé a un descuento especial antes de su vencimiento!',
        exampleNotificationCaption: 'Podés desuscribirte de las notificaciones cuando quieras!',
      },
      welcomeNotification: {
        disable: true,
      } });

      setOSInitiated(true);

      OneSignal.on('notificationPermissionChange', function(permissionChange) {
        var currentPermission = permissionChange.to;
        if (currentPermission === 'granted') {
          setNotification(true);
        }
      });

      const res = await OneSignal.getNotificationPermission();
      if (res !== 'granted') {
        // Trigger prompt
        setPrompt(true);
      } else {
        setNotification(true);
      }
    }

    if (auth) {
      startWebNotification();
    }
  }, [auth]);

  useEffect(() => {
    const sendDataToOneSignal = async () => {
      const u = await Store.getState().auth.user;
      await OneSignal.setExternalUserId(u._id);
      await OneSignal.setSubscription(true);
      await OneSignal.sendTags({
        id: u._id,
        email: u.email,
        logged: true,
        last_name: u.last_name,
        first_name: u.first_name,
        loyalty_points: u.rewardScore,
        national_identity: u.national_identity,
      });
    }

    if (notification && OSInitiated) {
      sendDataToOneSignal();
    }
  }, [notification, OSInitiated]);

  useEffect(() => {
    if (prompt && OSInitiated) {
      OneSignal.showSlidedownPrompt();
    }
  }, [prompt, OSInitiated]);

  return (
    <Provider store={Store}>
        <Loader />
        <Router>
          <Route path='/ingresa' component={Login} />
          <Route path='/registrate' component={SignUp} />
          <Route path="/recuperar-contrasena" component={ResetPassword} />
          <Route path="/terminos-y-condiciones" component={Terms} />
          {
            auth ? (
              <>
                <AppContainer>
                  <Route path='/' component={Promos} exact />
                  <Route path='/mi-cuenta/:tab?' component={MyAccount} exact />
                  <Route path='/perfil' component={Profile} exact />
                  <Route path='/promo/:id' component={PromoDetail} exact />
                  <Route path='/404' component={NotFound} />
                </AppContainer>
                <Route path="/validar-email" component={VerifyEmail} />
                <Route path="/validar-id" component={VerifyNationalIdentity} />
              </>
            ) : (
              unprotected.indexOf(window.location.pathname) === -1 && <Redirect to={`/ingresa${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />
            )
          }
      </Router>
    </Provider>
  );
};

export default App;
