const validateTo = (to) => {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    if (to) {
        to = to.split('-');
        if (to.length === 3) {
            to = new Date(to);
            if (isNaN(to.getTime())) {
                return today;
            }

            if (to <= today) {
                return to;
            } else {
                return today;
            }
            
        } else {
            return today;
        }
    } else {
        return today;
    }
};

const validateFrom = (from) => {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    if (from) {
        from = from.split('-');

        if (from.length === 3) {
            from = new Date(from);
            if (isNaN(from.getTime())) {
                return false;
            }

            if (from <= today) {
                return from;
            } else {
                return false;
            }
            
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const validateQueryDates = (to, from) => {
    to = validateTo(to);
    from = validateFrom(from);

    if (!from) {
        from = new Date(to.getFullYear(), to.getMonth() -1, to.getDate());
    } else {
        if (to < from) {
            from = new Date(to.getFullYear(), to.getMonth() -1, to.getDate());
        }
    }

    return { to, from }
};

const MONTH_NAMES = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Deciembre'
  ];
  
  
const getFormattedDate = (date, prefomattedDate = false, hideYear = false) => {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
  
    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }
  
    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } a las ${ hours }:${ minutes } hs`;
    }
  
    if (hideYear) {
      // 10. January at 10:20
      return `${ day } de ${ month } a las ${ hours }:${ minutes } hs`;
    }
  
    // 10. January 2017. at 10:20
    return `${ day } de ${ month } de ${ year }`;
};

// --- Main function
export const timeAgo = (dateParam) => {
    if (!dateParam) {
      return null;
    }
  
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
  
  
    if (seconds < 5) {
      return 'Ahora';
    } else if (seconds < 60) {
      return `Hace ${ seconds } segundos`;
    } else if (seconds < 90) {
      return 'Hace un minuto';
    } else if (minutes < 60) {
      return `Hace ${ minutes } minutos`;
    } else if (isToday) {
      return getFormattedDate(date, 'Hoy'); // Today at 10:20
    } else if (isYesterday) {
      return getFormattedDate(date, 'Ayer'); // Yesterday at 10:20
    } else if (isThisYear) {
      return getFormattedDate(date, false, true); // 10. January at 10:20
    }
  
    return getFormattedDate(date); // 10. January 2017. at 10:20
  }
  
