import setAuthorizationToken from '../utils/setAuthorizationToken';
import OneSignal from 'react-onesignal';

let initialState = {};

if (localStorage[`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`]) {
  initialState = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`));
  setAuthorizationToken(initialState.token);
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state = {
        isAuthenticated: true,
        token: action.token,
        user: action.payload
      }
      localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`, JSON.stringify(state));
      return state;
    
    case 'USER_DATA':
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      }
      localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`, JSON.stringify(state));
      return state;
    
    case 'EMAIL_VALID':
      state = {
        ...state,
        user: {
          ...state.user,
          email_validation: true,
        },
      }
      localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`, JSON.stringify(state));
      return state;
    
    case 'USER_IMAGE':
      state = {
        ...state,
        user: {
          ...state.user,
          profile_pic: [action.image]
        },
      }
      localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`, JSON.stringify(state));
      return state;
      
    case 'LOGOUT':
      state = {
        isAuthenticated: false,
        user: {},
        token: false,
      };
      // Logout on OneSignal
      localStorage.removeItem(`${process.env.REACT_APP_STORAGE_PREFIX}_AUTH`);
      OneSignal.sendTag("logged", false);
      return state;

    default:
      return state;
  };
};

export default authentication;