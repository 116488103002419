import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const initApp = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/app/init/web`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'POINTS', points: res.data.data.loyalty.points });
        Store.dispatch({ type: 'CATEGORY', category: res.data.data.loyalty.category });
        Store.dispatch({ type: 'USER_DATA', user: res.data.data.user });
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && (err.response.data.error === expired || err.response?.data?.message.name === expired)) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        throw err.response.data;
    }
};