export const months = [
    {
        value: 0,
        label: 'Enero',
    },
    {
        value: 1,
        label: 'Febrero',
    },
    {
        value: 2,
        label: 'Marzo',
    },
    {
        value: 3,
        label: 'Abril',
    },
    {
        value: 4,
        label: 'Mayo',
    },
    {
        value: 5,
        label: 'Junio',
    },
    {
        value: 6,
        label: 'Julio',
    },
    {
        value: 7,
        label: 'Agosto',
    },
    {
        value: 8,
        label: 'Septiembre',
    },
    {
        value: 9,
        label: 'Octubre',
    },
    {
        value: 10,
        label: 'Noviembre',
    },
    {
        value: 11,
        label: 'Diciembre',
    },
];

export const days = () => {
    const opts = [];
    for (let i = 0; i < 31; i++) {
        const obj = {
            value: i + 1,
            label: i + 1,
        };

        opts.push(obj);
    }

    return opts;
};

export const years = () => {
    const thisYear = new Date().getFullYear();
    const lowest = 1900;
    const opts = [];
    for (let i = thisYear; i >= lowest; i--) {
        const obj = {
            value: i,
            label: i,
        };

        opts.push(obj);
    }

    return opts;
};