import React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { connect } from 'react-redux';

const Loader = (props) => {
    return (
        <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={100}
        transitionLeaveTimeout={300}>
            {
                props.loading
                &&
                <div className="loader">
                    <div className="typing_loader"></div>
                </div>
            }
        </CSSTransitionGroup>
    );
};

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
})

export default connect(mapStateToProps)(Loader);