import axios from 'axios';
import Store from '../reducers';
import setAuthorizationToken from '../utils/setAuthorizationToken';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const login = async (email, password) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const data = {
            email,
            password,
            brand: process.env.REACT_APP_BRAND,
            platform: 'website'
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, data);

        const token = res.data.token;
        const payload = res.data.result;
        const action = {
            type: "LOGIN",
            token,
            payload
        };

        Store.dispatch(action);
        setAuthorizationToken(token);
        Store.dispatch({ type: 'LOADED' });

        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const checkEmailValidation = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/email_validation/check`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && (err.response.data.error === expired || err.response?.data?.message.name === expired)) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        return err.response.data;
    }
};

export const requestEmailValidation = async (fix) => {
    const data = {
        fix,
    };

    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/email_validation/request`, data);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }

        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const requestPasswordReset = async (email) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const data = {
            email,
            brand: process.env.REACT_APP_BRAND,
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgot`, data);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const verifyEmail = async (email) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const data = {
            email,
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/verify/email`, data);
        Store.dispatch({ type: 'LOADED' });

        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        return false;
    }
};

export const checkEmail = async (email) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const data = {
            email,
            brand: process.env.REACT_APP_BRAND,
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/check/email`, data);
        Store.dispatch({ type: 'LOADED' });

        if (res.data.found) {
            return false;
        }

        return true;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        return false;
    }
};

export const checkNationalIdentity = async (national_identity) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const data = {
            national_identity,
            brand: process.env.REACT_APP_BRAND,
        };

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/check/nationalIdentity`, data);
        Store.dispatch({ type: 'LOADED' });

        if (res.data.found) {
            return false;
        }

        return true;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        return false;
    }
};

export const createUser = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const fields = ['first_name', 'last_name', 'gender', 'phone_number', 'national_identity', 'email', 'fields', 'password', 'birthday'];

    try {
        const body = {
            platform: 'website',
            brand: process.env.REACT_APP_BRAND,
        };

        const additional = {};

        for (let key in data) {
            if (fields.indexOf(key) === -1) {
                additional[key] = data[key];
            } else {
                body[key] = data[key];
            }
        }

        body.fields = additional;

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/new`, body);

        const token = res.data.token;
        const payload = res.data.result;
        const action = {
            type: "LOGIN",
            token,
            payload
        };

        Store.dispatch(action);
        setAuthorizationToken(token);
        Store.dispatch({ type: 'LOADED' });

        return res.data;
    } catch(err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            throw err.response.data;
        }
        throw err;
    }
};

export const updateNationalIdentity = async (national_identity) => {
    const data = {
        national_identity,
    };

    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/update/nationalIdentity`, data);
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }

        if (err.response) {
            throw err.response.data;
        }
        throw err;
    }
};

export const updateUser = async (user) => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/update`, user);

        if (res.data.user) {
            const action = {
                type: "USER_DATA",
                user: res.data.user,
            };
    
            Store.dispatch(action);
        }

        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }

        if (err.response) {
            throw err.response.data;
        }
        throw err;
    }
};
