import TagManager from 'react-gtm-module';

export const sendEvent = (event, value) => {
    const tagManagerArgs = {
        dataLayer: {
            [event]: value,
        },
    };
    
    TagManager.dataLayer(tagManagerArgs)
};

export const sendCustomEvent = (event, key, value) => {
    const tagManagerArgs = {
        dataLayer: {
            event,
            category: 'ReachOut',
            [key]: value,
        },
    };
    
    TagManager.dataLayer(tagManagerArgs)
};

const e = {
    sendEvent,
    sendCustomEvent,
};

export default e;
