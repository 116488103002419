import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancelPoints;
export let cancelTransactions;

export const getLoyaltyStatus = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/loyalty/status`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelPoints = c;
                })
            }
        );
        if (res.data.success) {
            Store.dispatch({ type: 'POINTS', points: res.data.data.points });
            Store.dispatch({ type: 'CATEGORY', category: res.data.data.category });
        }
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getLoyaltyTransactions = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/loyalty/transactions`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelTransactions = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};