import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import MenuItem from './Menu/MenuItem';
import * as Icon from 'react-feather';
import { initApp, cancel } from '../services/app';
import Analytics from '../utils/analytics';
/* IMAGES */
import Avatar from '../assets/img/placeholders/avatar.png';
import Logo from '../assets/svg/brand/logo.svg';
import RogLogo from '../assets/reachout/reachout.png';

/* SVG */
import {ReactComponent as AccountIcon} from '../assets/account.svg';
import {ReactComponent as RedeemsIcon} from '../assets/redeem.svg';

const AppContainer = (props) => {
    const [compact, setCompact] = useState(true);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        if (props.user._id) {
            Analytics.sendCustomEvent('set_user_id', 'userId', props.user._id);
        }
    }, [props.user._id]);

    useEffect(() => {
        if (mobile) {
            setCompact(false);
        } else {
            setCompact(true);
        }
    }, [mobile])

    useEffect(() => {
        if (props.auth) {
            initApp();
        }

        return () => {
            if (cancel) {
                cancel();
            }
        }
    }, [props.auth])

    return (
        <>
        {
            props.user && !props.user.email_validation ? (
                <Redirect to="/validar-email" />
            ) : props.user && props.user.national_identity.indexOf('RANDOM_') !== -1 ? (
                <Redirect to="/validar-id" />
            ) : (
                <div className={`${compact ? 'sidebar-compact' : ''} ${mobile ? 'sidebar-menu' : ''}`}> 
                    <nav className="navbar navbar-vertical navbar-expand-lg navbar-light">
                        <span className="navbar-brand mx-auto d-none d-lg-block my-0 my-lg-4">
                            <img src={Logo} className="brand-icon-lg" alt="Equus" />
                            <img src={Logo} className="muze-icon" alt="Equus" />
                        </span>
                        <div className="navbar-collapse">
                            <ul className="navbar-nav mb-2" id="accordionExample" data-simplebar="init">
                                <div className="simplebar-wrapper">
                                    <div className="simplebar-height-auto-observer-wrapper">
                                        <div className="simplebar-height-auto-observer"></div>
                                    </div>
                                    <div className="simplebar-mask">
                                        <div className="simplebar-offset">
                                            <div className="simplebar-content-wrapper">
                                                <div className="simplebar-content">
                                                    <MenuItem name="Canjeá" screen={'/'} exact Icon={RedeemsIcon} />
                                                    <MenuItem name="Mi Cuenta"screen={'/mi-cuenta'} Icon={AccountIcon} />
                                                    {/* <SectionDivider>Pages</SectionDivider>
                                                    <Divider /> */} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                            <div className="navbar-vertical-footer border-top border-gray-50">
                                <a href="https://www.reachout.global/" target="_blank" rel="noreferrer" className="d-flex justify-content-center">
                                    <img src={RogLogo} alt="ReachOut" className="reachout-logo" />
                                </a>
                            </div>
                        </div>
                    </nav>
                    <div className="main-content">
                        <div className="header border-bottom border-gray-200 header-fixed">
                            <div className="container-fluid px-0">
                                <div className="header-body px-3 px-xxl-5 py-3 py-lg-4">
                                    <div className="row align-items-center">
                                        <span onClick={() => setMobile(state => !state)} className="muze-hamburger d-block d-lg-none col-auto">
                                            { !mobile ? <Icon.Menu size={20} /> : <Icon.X size={20} /> }
                                        </span>
                                        <span className="navbar-brand mx-auto d-lg-none col-auto px-0">
                                            <img src={Logo} alt="Muze" />
                                        </span>
                                        <div className="col d-flex align-items-center">
                                            <button onClick={() => setCompact(state => !state)} className="back-arrow bg-white circle circle-sm shadow border border-gray-200 rounded mb-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 16 16">
                                                    <g data-name="icons/tabler/chevrons-left" transform="translate(0)">
                                                        <rect data-name="Icons/Tabler/Chevrons Left background" width="16" height="16" fill="none"></rect>
                                                        <path d="M14.468,14.531l-.107-.093-6.4-6.4a.961.961,0,0,1-.094-1.25l.094-.107,6.4-6.4a.96.96,0,0,1,1.451,1.25l-.094.108L10,7.36l5.72,5.721a.961.961,0,0,1,.094,1.25l-.094.107a.96.96,0,0,1-1.25.093Zm-7.68,0-.107-.093-6.4-6.4a.961.961,0,0,1-.093-1.25l.093-.107,6.4-6.4a.96.96,0,0,1,1.45,1.25l-.093.108L2.318,7.36l5.72,5.721a.96.96,0,0,1,.093,1.25l-.093.107a.96.96,0,0,1-1.25.093Z" transform="translate(0 1)" fill="#6C757D"></path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-wrap align-items-center icon-blue-hover ps-0">
                                            <div className="dropdown profile-dropdown">
                                                <span className="cursor-pointer avatar avatar-sm avatar-circle ms-4 ms-xxl-5" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuButton">
                                                    <img className="avatar-img" src={Avatar} alt="Avatar" />
                                                </span>
                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                                    <li className="pt-2 px-4">
                                                        <span className="fs-16 font-weight-bold text-black-600 Montserrat-font me-2">{props.user.name}</span>
                                                        <small className="text-gray-600 pb-3 d-block">DNI {props.user.national_identity}</small>
                                                    </li>
                                                    <li>
                                                        <NavLink className="dropdown-item" to="/perfil">
                                                            <Icon.User size={20} /><span className="ms-2">Mi Perfil</span>
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <hr className="dropdown-divider" />
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item" onClick={props.logout}>
                                                            <Icon.LogOut size={20} /><span className="ms-2">Cerrar Sesión</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { props.children }
                        <div className="p-3 p-xxl-5">
                            <footer className="pt-xl-5 mt-lg-2">
                                <div className="container-fluid px-0 border-top border-gray-200 pt-2 pt-lg-3">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <p className="fs-16 text-gray-600 mt-2">2021 © Equus - Todos los derechos reservados.</p>
                                            <small className="text-gray-600 mb-2">Desarrollado por <a href="https://www.reachout.global/" target="_blank" rel="noreferrer" className="text-black fw-bold">ReachOut</a></small>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="nav navbar">
                                                <li><NavLink to="/terminos-y-condiciones">Términos y condiciones</NavLink></li>
                                                <li><a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>Contacto</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth.isAuthenticated,
    user: state.auth.user || {},
    loyalty: state.loyalty || {},
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: 'LOGOUT' }),
    setPoints: (points) => dispatch({ type: 'POINTS', points }),
    setCategory: (category) => dispatch({ type: 'CATEGORY', category })
 });

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);