import React from 'react';
import QRcode from '../../assets/svg/app/qrcode.svg';

const AppDownloadModal = (props) => {
    return (
        <div className={`modal fade ${props.show && 'show d-block bg-darkened'}`}>
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div className="modal-content shadow-dark-80">
                    <div className="modal-header border-0 pb-0 justify-content-center align-items-center ps-4 mb-2">
                        <h5 className="modal-title pt-3" id="exampleModalLabel">Escaneá el código con tu teléfono</h5>
                    </div>
                    <div className="modal-body pt-2 px-4 justify-content-center text-center">
                        <img src={QRcode} alt="QR" />
                    </div>
                    <div className="modal-footer row">
                        <button type="button" className="btn btn-link mb-2" onClick={() => props.setShow(false)}><span className="px-1">Cerrar</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AppDownloadModal;
