import axios from 'axios';
import Store from '../reducers';

const CancelToken = axios.CancelToken;
export let cancel;

export const getAssets = async () => {
    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/assets/web?brand=${process.env.REACT_APP_BRAND}`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );

        if (res.data) {
            const assets = {};

            res.data.forEach(a => {
                assets[a.name] = a.type === 'image' ? a.url : a.text;
            })
            
            Store.dispatch({ type: 'SET_ASSETS', assets });
        }
        
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        return err;
    }
};