import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AfterHeader from '../components/AfterHeader';
import AppDownloadModal from '../components/App/AppDownloadModal';
import { getPromos, cancel } from '../services/promos';

const Promos = (props) => {
    const [promos, setPromos] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        document.title = 'EQS CLUB - Promociones';
        // GET PROMOS
        loadPromos();

        return cancel;
    }, []);

    const loadPromos = async () => {
        const p = await getPromos();
        setPromos(p.promos.sort((a, b) => a.points > b.points ? 1 : -1));
    }

    return (
        <main>
            <AfterHeader text="Catálogo" small={"Canjeá tus puntos"} />
            <div className="row w-100 mt-5 py-2 px-5">
                {
                    promos.map(promo => (
                        <div className="col-lg-4 col-xl-3 col-sm-6 col-xs-12" key={promo._id}>
                            <NavLink to={`/promo/${promo._id}`} className="card promo-card mb-4">
                                <img className="card-img-top" src={promo.images[0].url} alt={promos.description_title} />
                            </NavLink>
                        </div>
                    ))
                }
            </div>
            <div className="row w-100 py-2 px-5">
                <div className="alert-background catalog-background p-4 p-md-5 position-relative overflow-hidden rounded-12" style={{ backgroundImage: `url('${props.assets.app_download_background_web}')`}}>
                    <div className="row mb-0 mb-sm-5 mb-md-0 ps-xl-3">
                        <div className="col-lg-12 col-xl-8 col-xxl-4 mb-md-5 mb-lg-0 position-relative z-index-top">
                            <h2 className="my-2 ">Descargá la app para no perderte ningún beneficio</h2>
                            <button onClick={() => setModal(true)} className="btn btn-primary text-uppercase">Descargar</button>
                        </div>
                    </div>
                    <div className="alert-overlay z-index-background"></div>
                </div>
            </div>
            <AppDownloadModal show={modal} setShow={setModal} />
        </main>
    )
};

const mapStateToProps = state => ({
    assets: state.assets,
});

export default connect(mapStateToProps)(Promos);
