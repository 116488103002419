import React from 'react';

const AfterHeader = (props) => { 
    return (
        <div className="px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header">
            <div className="container-fluid px-0">
                <div className="row align-items-center">
                    <div className="col">
                        <span className="text-uppercase tiny text-gray-600 Montserrat-font font-weight-semibold">{props.small}</span>
                        <h1 className="h2 mb-0 lh-sm" dangerouslySetInnerHTML={{ __html: props.text }}></h1>
                    </div>
                    <div className="col-auto d-flex align-items-center my-2 my-sm-0">
                        {
                            props.button && props.button()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AfterHeader;
