import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const redeem = async (id, confirmation) => {
    const data = {};

    if (confirmation) {
        data.confirmation = true;
    }

    Store.dispatch({ type: 'LOADING' });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/redeems/new/${id}`, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return res.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};

export const getRedeems = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/redeems/validator`,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        return res.data;
    } catch (err) {
        if (err.response && err.response.data.error === expired) {
            Store.dispatch({ type: 'LOGOUT' });
        }
        if (err.response) {
            return err.response.data;
        }
        return err;
    }
};