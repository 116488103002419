const initialState = { points: 0, category: '' };

const loading = (state = initialState, action) => {
  switch (action.type) {
    case 'POINTS':
      state = {
        ...state,
        points: action.points
      }
      return state;
    case 'CATEGORY':
        state = {
            ...state,
            category: action.category
        }
      return state;
    default:
      return state;
  };
};

export default loading;