import React, { useEffect } from 'react';
import {connect} from 'react-redux';

const Terms = () => {
    useEffect(() => {
        document.title = 'EQS CLUB - Términos y Condiciones';
    }, []);
    
    return (
        <>
            <div className="container-fluid fs-overlay">
                <div className="row">
                    <div className="p-3 p-xxl-5 bg-primary after-header">
                        <div className="container-fluid px-0 pb-5">
                            <div className="row">
                                <div className="col-md-10 col-lg-12 col-xxl-6 text-center pb-1 pt-4 px-4 mx-auto">
                                    <h1 className="display-4 text-white mb-2">Términos y Condiciones</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <h4>1. CONTRATO DE LICENCIA DE USUARIO FINAL</h4>
                            <p>Este Acuerdo de Licencia de Usuario Final ("CLUF") es un contrato legal vinculante entre usted, como individuo o entidad, y Floki SA, propietaria de la aplicación Equus (de ahora en más "Equus").  Al descargar, instalar o utilizar esta aplicación para Android, iOS u otra plataforma móvil, según el caso (de ahora en más el "Software"), usted acepta quedar obligado por los términos de este Contrato de Licencia de Usuario Final, de ahora en adelante CLUF. Si no está de acuerdo con los términos de la licencia, no active la casilla "Acepto las condiciones" y no podrá utilizar el Software.
                            Usted está de acuerdo que la instalación o el uso del Software significa que ha leído, entendido y acepta estar obligado por el CONTRATO DE LICENCIA DE USUARIO FINAL.
                            El Software se proporciona en virtud de este CLUF únicamente para su uso privado y no comercial.</p>
                            <h5>1.1. DESCRIPCIÓN DEL SOFTWARE</h5>
                            <p>El Software es una aplicación de software descargable que permite acceder a funcionalidad, servicios y beneficios propuestos por Equus directamente desde tu Android, iPhone, iPad o cualquier otro dispositivo móvil compatible ("Dispositivo"). Puede descargar el Software en forma gratuita desde la Tienda Online de Aplicaciones de su sistema móvil, ya sea que Ud. sea un miembro registrado anteriormente o no, pero debe asociarlo a una cuenta existente o registrarse creando una nueva cuenta, siguiendo las indicaciones del Software, para permitir que su funcionalidad sea completa. Sin ser una enunciación limitante, el uso del Software está destinado a, visualizar contenidos, promociones, participar en actividades lúdicas individuales o sociales, canjear promociones y/o beneficios, consultar puntos/saldos disponibles, sumar puntos o cualquier otro indicador de participación y/o transacción, buscar comercios de interés por rubro y/o ubicación geográfica, publicar y/o compartir contenidos generados por Ud., acceder a sitios web propios o de terceros, registrar compras realizadas, y cualquier otra Funcionalidad que Equus desee incluir en ésta o futuras versiones del Software.</p>
                            <h5>1.2. LICENCIA</h5>
                            <p>Equus le otorga, sujeto a los términos y condiciones de este Acuerdo, una licencia no exclusiva, no transferible que le permite:</p>
                                <p>Utilizar el software para su uso personal.</p>
                                <p>Instalar el Software en un solo dispositivo; y</p>
                                <p>Hacer una copia del Software en un formato legible por máquina exclusivamente para fines de copia de seguridad, siempre que se reproduzcan el Software en su forma original y con todos los avisos de la copia de seguridad.
                                Para mayor claridad, lo anterior no tiene por finalidad prohibir instalación y copia de seguridad del software en otros dispositivos en los que se acordó también el CONTRATO DE LICENCIA DE USUARIO FINAL. Cada una de las instancias de este CLUF que usted está de acuerdo le otorga a los derechos antes mencionados en relación con la instalación, el uso y el back-up de una copia del Software en un dispositivo.</p>
                            <h5>1.3. TÍTULO DE PROPIEDAD</h5>
                            <p>La propiedad y todos los derechos (incluyendo sin limitación derechos de propiedad intelectual) del Software permanecen con Equus. Con excepción de los derechos expresamente reconocidos en la presente Licencia, no se concederán otros derechos, ya sea expresa o implícita.</p>
                            <h5>1.4. RESTRICCIONES</h5>
                            <p>Usted entiende y acepta que usted sólo puede usar el Software de tal manera que cumpla con todas las leyes aplicables en las jurisdicciones en que se utilice el Software. Su uso se hará de conformidad con las restricciones del caso acerca de la privacidad y los derechos de propiedad intelectual.</p>
                            <p>Usted no puede:</p>
                            <p>Crear versiones de otros Software derivados del Software;</p>
                            <p>Utilizar el software para cualquier propósito que no sea el descrito en este documento.</p>
                            <p>Copiar o reproducir el Software, excepto como se describe en la presente licencia;</p>
                            <p>Vender, asignar, licenciar, divulgar, distribuir o transferir de cualquier otra forma poner a disposición del Software o de cualquier copia del Software de ninguna forma a terceras partes.</p>
                            <p>Modificar, traducir, descompilar, desensamblar ni realizar ingeniería inversa en el Software, o la tentativa de lo anterior, salvo en la medida en que esta prohibición no está permitida en la legislación aplicable; o</p>
                            <p>Quitar o modificar ningún aviso de propiedad o marcas en el Software.</p>
                            <p>Subir, compartir y/o publicar textos que no sean de su exclusiva autoría y para los cuales posea todos los derechos de publicación necesarios</p>
                            <p>Subir, compartir y/o publicar fotografías y/o videos que no sean de su exclusiva autoría y para los cuales posea todos los derechos de publicación necesarios.</p>
                            <p>Subir, compartir y/o publicar textos, fotos, audios y/o videos que sean difamatorios, ofensivos para otros miembros usuarios del Software, que sean contrarios a principios éticos y/o morales generalmente aceptados y/o a las normas legales.</p>
                            <p>El incumplimiento de estas restricciones producirá la baja inmediata de Ud. como usuario del Software impidiendo al infractor a continuar utilizando el mismo. Sin perjuicio de lo expuesto Equus se reserva el derecho de iniciar las acciones legales necesarias a efectos de lograr un resarcimiento para sí y/o para los terceros damnificados por la acción indebida desarrollada.</p>
                            <h5>1.5. INFORMACIÓN PERSONAL Y PRIVACIDAD</h5>
                            <p>Puede que le solicitemos que nos proporcione cierta información acerca de usted durante el proceso de descarga de Software y/o durante el uso del mismo. El software podría registrar la actividad que Ud. desarrolla con él a efectos de mejorar su experiencia de uso y proveerle contenidos y servicios más relevantes acorde a sus intereses. Toda la información personal que usted nos proporcione, así como la que el Software infiera de las actividades que Ud. desarrolla con él, se regirá por la Política de Privacidad, que está disponible en https://www.equus.com.ar/ La información personal que usted proporcione durante la fase de registración y/o durante el uso del Software también se rige por la Política de Privacidad. Al optar por utilizar el Software y/o el, usted indica su comprensión y aceptación del CLUF y la Política de Privacidad. Nunca venderemos ni compartiremos su información personal con otras empresas. Sin embargo, Usted entiende y acepta que Equus puede revelar información cuando así lo requiera la ley o en la creencia de buena fe que dicha revelación es razonablemente necesaria para cumplir con procesos legales, hacer cumplir los términos de este CLUF, o para proteger los derechos, propiedad o seguridad de Equus, sus usuarios o el público.</p>
                            <h5>1.6. PROTECCIÓN DE DATOS PERSONALES</h5>
                            <p>Los soportes de bases de datos utilizados por el software han sido inscriptos en el Registro Nacional de Bases de Datos bajo el Formulario FB.01 Nro 1674, Solicitud de Registro Nro 152195154931, cumplimentando los requerimientos que establece la Ley de Protección de Datos Personales Nro 25.326 respecto a Protección, seguridad, acceso y procedimientos para la modificación y eliminación de Datos Personales.</p>
                            <h5>1.7. DETERMINANDO Y COMPARTIENDO UBICACIÓN</h5>
                            <p>El software utiliza la ubicación del usuario para personalizar el servicio. Cuando el usuario abre, utiliza e interactúa con el software, el mismo accede a la información de ubicación del dispositivo (latitud y longitud). Esta información no es compartida con terceros. Al mismo tiempo, el software puede acceder a la información de ubicación en segundo plano (cuando el usuario haya provisto los permisos e instalado el software) para proveer los servicios, incluyendo el envío de notificaciones. Si el usuario activa la funcionalidad “Ubicación en segundo plano”, la aplicación Equus accederá, de forma automática y sin interacción humana, a los datos de ubicación del dispositivo.</p>
                            <h5>1.8. SIN GARANTÍA</h5>
                            <p>Equus no garantiza que las funciones contenidas en el software vayan a cumplir con las necesidades o requerimientos que se pueda tener, o que el software funcionará libre de errores, o de forma ininterrumpida, o que cualquier defecto o error será corregido, o que el software es totalmente compatible con cualquier plataforma en particular. El software se ofrece en el estado "tal cual es" y sin garantía, ya sea expresa o implícita. Equus expresamente niega todas las garantías de cualquier tipo, ya sea expresa o implícita, incluyendo, pero no limitado a, las garantías implícitas de comerciabilidad y/o idoneidad para un propósito particular. Usted acepta que la información que se presenta en el software no posee garantía alguna de veracidad o certeza por lo que no podrá ejercer reclamo alguno por decisiones que Usted tomara basado en la información presentada. Es de su exclusiva responsabilidad realizar las verificaciones correspondientes antes de tomar acción alguna. Sin ser una enunciación abarcativa queda bajo su responsabilidad verificar direcciones, horarios, ubicaciones indicadas mediante GPS, promociones vigentes, actividades sugeridas, datos de contacto, rutas y derroteros entre otras.  Algunas jurisdicciones no permiten la renuncia o la exclusión de garantías implícitas, por lo que es posible que no se apliquen a usted.</p>
                            <h5>1.9. DERECHO A RESCINDIR O MODIFICAR EL SOFTWARE</h5>
                            <p>Equus podrá modificar el Software y este CLUF con o sin aviso previo. Cualquier modificación se reflejará en https://www.equus.com/ y/o en la sección “Términos y condiciones” de la aplicación descargada en su dispositivo móvil. Las modificaciones podrían incluir, sin ser una enunciación extensiva, el cobro de tarifas por el Software, cambiar la funcionalidad y/o la apariencia del Software. En el caso que Equus modifique el Software o los términos de la licencia, usted puede terminar este CLUF y dejar de utilizar el Software. Equus podrá poner fin a su uso del Software y dar por finalizado el presente CLUF en cualquier momento, con o sin previo aviso.</p>
                            <h5>1.10. OPEN SOURCE</h5>
                            <p>El Software puede contener porciones de software de fuente abierta. Cada elemento del software de fuente abierta está sujeto a sus propias condiciones de licencia aplicables. Los Derechos de Autor del software de código abierto están en manos de los respectivos titulares de los derechos de autor de dicho código.</p>
                            <h5>1.11. INDEMNIZACIÓN</h5>
                            <p>Al aceptar el CLUF, usted se compromete a indemnizar y mantener indemne a Equus, sus filiales, empresas asociadas y otros socios comerciales, de cualquier daño directo o indirecto que surja como consecuencia de la utilización indebida que Ud. realice del Software o de cualquier otro asunto relacionado con el Software.</p>
                            <h5>1.12. LIMITACIÓN DE RESPONSABILIDAD</h5>
                            <p>Usted reconoce y acepta expresamente que Equus no será responsable de ningún daño indirecto, incidental, consecuente del uso que usted haga del software, incluyendo, pero no limitado a, daños y perjuicios, daños por pérdida de datos, de dinero, de ingresos, de puntos del programa de lealtad, de beneficios de cualquier índole, u otras pérdidas intangibles (incluso si Equus hubiera sido advertido de la posibilidad de tales daños). algunas jurisdicciones no permiten la limitación de los daños y perjuicios y/o exclusiones de responsabilidad por daños incidentales o consecuentes. en este sentido, algunas de las limitaciones anteriores pueden no aplicarse a usted.</p>
                            <h5>1.12. GENERAL</h5>
                            <p>El acuerdo de licencia entre usted y Equus fija jurisdicción en la Ciudad Autónoma de Buenos Aires, siendo ese el foro exclusivo para cualquier disputa derivada o relacionada con este CONTRATO. El contrato de licencia constituye el acuerdo completo entre usted y Equus con respecto al Software. Si alguna disposición de este CLUF es considerada por un tribunal de jurisdicción competente para ser contraria a la ley, tal disposición será cambiada e interpretada con el fin de cumplir mejor los objetivos de la disposición original hasta el máximo permitido por la ley y el resto de las disposiciones de este acuerdo permanecerá en pleno vigor y efecto. Usted no puede ceder este Contrato y la cesión de este CLUF por usted será nula y sin valor.  Otros logotipos y nombres comerciales y/o marcas que puedan aparecer dentro del contenido de Equus son marcas registradas de sus respectivos dueños. Usted se compromete a no utilizar estas marcas en cualquier forma sin permiso previo y por escrito de los titulares de dominio. Los títulos de sección y la numeración de este CLUF se muestran para su comodidad y no producen efectos jurídicos.</p>
                            <br />
                            <h4>2. TÉRMINOS Y CONDICIONES – PROGRAMA EQS CLUB</h4>
                            <p>El Programa EQS CLUB (en adelante, el “Programa”) premia la fidelidad de los clientes de Floki SA posibilitando a los mismos obtener diversos beneficios (en adelante, los “Beneficios”. El cliente que desee podrá adherirse al Programa descargando alguna de las Aplicaciones móviles de las marcas del Grupo, Equus o cualquier otra marca que pudiera formar parte del Programa. Dicha Aplicación móvil, disponible en forma gratuita para descarga en las tiendas de los sistemas Android y Apple, serán los soportes digitales del Programa (en adelante, la “App”) que permitirá acceder a los Beneficios que estarán disponibles en los locales adheridos y en el sitio de E-commerce de acuerdo a los siguientes Términos y Condiciones que seguidamente se detallan:</p>
                            <h5>2.1 ADHESIÓN</h5>
                            <p>2.1.1. Podrá ser titular de la App (en adelante, el “Titular”) toda persona física mayor de dieciocho años de edad, que resida en el territorio de la República Argentina.</p>
                            <p>2.1.2. La aceptación de ser Titular no le insume costo alguno.</p>
                            <p>2.1.3. La aceptación de los Términos y Condiciones del Programa se materializará mediante el uso que el Titular realice respecto de la App. La sola descarga y activación de la App en su dispositivo móvil configura la aceptación de los presentes Términos y Condiciones. En el caso de las actualizaciones de los Términos y Condiciones del Programa, la aceptación se materializará mediante el sostenido uso que el titular realice respecto de la App con posterioridad a la entrada en vigencia de las actualizaciones. Los Términos y Condiciones se aplicarán a todas las transacciones bajo el Programa que efectúe el titular con posterioridad a la aceptación por Floki S.A. (en adelante la “Empresa”) de la solicitud de adhesión realizada durante el proceso de registro en la propia App desde el celular del solicitante, la que se exteriorizará a través de la App con un mensaje de confirmación y/o con la creación de la cuenta de nuevo usuario en los sistemas de Fidelidad que Gestionan el Programa.</p>
                            <p>2.1.4. Los Titulares deberán notificar a la mayor brevedad posible a la Empresa, por medio del formulario de Perfil de usuario existente en la App, cualquier cambio de domicilio para su actualización en la base de datos del Programa.</p>
                            <p>2.1.5. La Empresa podrá, sin expresión de causa, rechazar cualquier solicitud de adhesión al Programa.</p>
                            <h5>2.2 APLICACIÓN EQS CLUB</h5>
                            <p>2.2.1. La Aplicación correctamente instalada en el celular del Titular, luego de completar el proceso de Registro y Login, será el soporte digital del Programa. La App cumple la función de identificación del Titular. Los datos ingresados por el Titular durante el proceso de Registro, entre los que se encuentra en carácter de mandatorio el número de su DNI, se utilizarán para crear un registro del Cliente en los sistemas de Gestión del Programa, permitiendo al Titular el acceso a distintos Beneficios en la compra o contratación que se realice en los locales y en el e-commerce. La App (o el Programa) no es una tarjeta de crédito, ni de compra, ni de débito, no sirve como medio de pago. No almacena ni representa dinero en efectivo ni los puntos acumulados en la cuenta del Titular poseen equivalencia alguna de valor económico.</p>
                            <p>2.2.2. La App registra la cantidad de Puntos que los Titulares adquieran. Los Titulares adquirirán un (1) Punto por cada diez (10) pesos de compra facturados al titular de la cuenta del programa de fidelización que realicen en los locales y en el/los e-commerce de las Marcas participantes. También podrán sumar Puntos en eventos, acciones u otras promociones que la Empresa organice por si o por medio de terceros ya sea que impliquen o no obligación de compra.</p>
                            <p>2.2.3. Cuando el cliente realice una devolución parcial o total de un pedido, además de realizar la devolución de dinero, se emitirá una nota de crédito por los productos que corresponda y se descontarán los puntos correspondientes al o los productos que devolvió.</p>
                            <p>2.2.4. La empresa a su exclusivo criterio, podrá actualizar el monto de pesos necesarios para obtener un punto.</p>
                            <p>2.2.5. Los Puntos acumulados en el Programa no constituyen propiedad de los Clientes y perecen o caducan con la finalización del Programa, como así́ también, una vez transcurrido el plazo establecido desde la obtención de los puntos. Queda expresamente establecido que dicho plazo será́ de 180 (ciento ochenta) días. De darse alguno de los supuestos la Empresa procederá a la baja de los puntos de manera automática y sin necesidad de notificación alguna. Los Puntos no son transferibles a ninguna otra persona o entidad ni bajo ningún título o causa y tampoco podrán ser transferidos a ninguna otra cuenta del Programa.</p>
                            <p>2.2.6. La App es personal e intransferible y sólo puede ser utilizada por el Titular en sus dispositivos móviles. La App no tiene fecha de vencimiento.</p>
                            <p>2.2.7. “La Empresa” se reserva el derecho de cancelar la participación del Titular en el Programa y bloquear el acceso de la App a los servidores centrales que gestionan el Programa a su sólo criterio, sin necesidad de notificación alguna.</p>
                            <p>2.2.8. Se considerará a una App y su cuenta asociada como ilegal cuando por cualquier medio o procedimiento (hackeo, ataque, intrusión, descompilación, etc.) se altere o intente alterar el contenido de la App, así como los puntos de la cuenta del Titular. Estas cuentas ilegales quedarán fuera del Programa y no gozarán de los Beneficios que el mismo brinda a las Apps legales. La Empresa se reserva el derecho de iniciar las acciones legales que correspondan contra cualquier persona que intente un uso fraudulento de una App y/o del Programa.</p>
                            <h5>2.3 BENEFICIOS</h5>
                            <p>2.3.1. El Programa otorga Beneficios a sus titulares en las sucursales de los Locales del Grupo Floki SA y/o comercios adheridos, los que serán comunicados en cada oportunidad.</p>
                            <p>2.3.2. Los Beneficios dependerán de la cantidad de Puntos acumulados. Los Beneficios podrán consistir en regalos, descuentos variables, invitaciones, adelantos y/o prórrogas de temporadas y liquidaciones entre otros, los cuales serán notificados oportunamente a los Titulares por los medios que la Empresa considere adecuados y a través de la App. La Empresa podrá establecer límites de disponibilidad para utilizar el Beneficio por cada titular.</p>
                            <p>2.3.3. En ningún caso y bajo ninguna circunstancia estará permitido canjear Beneficios por dinero en efectivo.</p>
                            <h5>2.4 MODO DE USO DE LA APP Y ACCESO A LOS BENEFICIOS</h5>
                            <p>2.4.1. El titular podrá acceder a los Beneficios otorgados con la exhibición de la App al personal de la Empresa y de un documento que acredite la identidad del titular de la misma.</p>
                            <p>2.4.2. Para el caso que el Beneficio estuviera asociado a una compra, el titular sólo podrá obtener el Beneficio otorgado si presenta la App y su DNI respaldatorio antes de la emisión de la factura correspondiente por el producto adquirido.</p>
                            <p>2.4.3. En caso que el sistema se encuentre fuera de servicio por cualquier motivo o que la App se encuentre dañada al momento de la compra o contratación, la sucursal confeccionará un comprobante de forma manual.</p>
                            <p>2.4.4. La App podrá entregar códigos de validación en pantalla que deberán ser presentados al cajero al momento de reclamar un beneficio. Sin la presentación de dichos códigos los cajeros y personal de la Empresa NO están autorizados a entregar el beneficio.</p>
                            <p>2.4.5. Los códigos entregados por la app tienen una validez de 30 días corridos desde la entrega del mismo. La empresa podrá modificar los períodos de validez sin previo aviso.</p>
                            <p>2.4.6. Se considera un uso Fraudulento de la APP y del Programa, sea por manipulación por medios digitales y/o capturas de pantallas, la reutilización de los códigos de validación que entrega la APP. El uso duplicado fraudulento de códigos para obtener beneficios en forma indebida será motivo de cancelación de la participación en el programa con la pérdida del total de los puntos acumulados.</p>
                            <p>2.4.7. La App utiliza una serie de Algoritmos para mostrar los contenidos y beneficios. Algunos de estos contemplan, sin ser una descripción abarcativa, la localización del Cliente, el perfil e intereses, sus patrones de compras anteriores, fechas de cumpleaños, la relación de amistad con otros clientes del Programa, la disponibilidad de stock, etc. La Empresa no se responsabilizará ni ofrecerá compensación alguna en casos de que los resultados presentados al Cliente sean incorrectos, sin importar si el fallo es debido a problemas técnicos del dispositivo del cliente, de los servidores centrales o se debe a fallos en el software que administra el Programa. Al aceptar los Términos y Condiciones el Cliente renuncia expresamente a iniciar una demanda por daños y perjuicios por inconvenientes surgidos como producto de la utilización de la App.</p>
                            <h5>2.5 PROMOCIONES Y SORTEOS. ACCIONES PUBLICITARIAS</h5>
                            <p>2.5.1. En forma directa o a través de terceros la Empresa, con la frecuencia que establezca a su exclusivo criterio, podrá realizar promociones y sorteos de bienes y/o servicios, de conformidad con las bases y condiciones que se determinen en cada oportunidad.</p>
                            <p>2.5.2. En dichas promociones y/o sorteos no podrán participar empleados y contratados directos de la Empresa, así como tampoco sus familiares parientes por consanguinidad o afinidad en segundo grado.</p>
                            <p>2.5.3. Adicionalmente, la Empresa podrá emprender acciones publicitarias y promocionales con terceros comercios adheridos al Programa, a fin de acercarle al Titular diversa información, ofertas u otros beneficios que pueden resultar de su interés.</p>
                            <p>2.5.4. La entrega de los Beneficios estará sujeta a disponibilidad de stock. Ninguno de los Beneficios podrá ser canjeado por dinero en efectivo.</p>
                            <h5>2.6 CANCELACIÓN</h5>
                            <p>2.6.1. La Empresa podrá cancelar o finalizar cualquier adhesión de los Titulares al Programa sin aviso previo y sin expresión de causa, cuando  el Titular incurra en alguno de los siguientes supuestos : a) no cumpliera con los Términos y Condiciones; b) abusara de cualquier privilegio concedido bajo el Programa; c) proveyera cualquier información falsa a la Empresa  o a cualquier punto de venta adherido; d) pretendiera vender a terceros los beneficios; e) atente contra la integridad de los datos de la App y/o servidores de gestión del Programa.</p>
                            <p>2.6.2. Finalizada la adhesión por cualquier causa, la información relativa al Titular existente permanecerá en la base de datos de la Empresa. El Titular gozará de los derechos conferidos por la Ley N° 25.326 (Ley de Protección de los Datos Personales).</p>
                            <h5>2.7 CONDICIONES GENERALES</h5>
                            <p>2.7.1. En cualquier momento, la Empresa podrá efectuar cambios a estos Términos y Condiciones, a la denominación del Programa y a su logo, a los Beneficios publicados, las condiciones de participación y la cantidad y características de los locales adheridos. Las modificaciones mencionadas precedentemente, serán informadas a los Titulares por cualquier medio masivo de comunicación, a través del Sitio de Internet o de cualquier otro que implique su difusión pública, a elección de La Empresa.</p>
                            <p>2.7.2. La Empresa podrá efectuar cambios en cualquier momento en los Beneficios incluidos en el Programa y en las condiciones para el acceso a dichos Beneficios y la vigencia de los mismos.</p>
                            <p>2.7.3. La información concerniente a los Titulares y la referida a las transacciones que resulten en la utilización de la App (en adelante la "Información") será almacenada y custodiada por la Empresa. La Información estará a disposición de la Empresa para su utilización dentro del Programa y en tanto resulte necesario, estará a disposición de los establecimientos adheridos autorizados de la Empresa y con fines publicitarios y promocionales, tal como se indica en el apartado 5) de los presentes Términos y Condiciones del Programa. El Programa utiliza los datos para conocer los intereses y/o afinidades de los titulares de tal forma que los Beneficios se adecuen a los intereses de los mismos y para el máximo rendimiento del Programa.</p>
                            <p>2.7.4. El titular expresamente acepta y acuerda:</p>
                            <p>(a) Proveer la información solicitada para adherirse al Programa y autorizar a la Empresa al acceso y tratamiento de la información allí contenida;</p>
                            <p>(b) Que la Empresa trate y/o transfiera los datos a los establecimientos adheridos, a fin de que los mismos le envíen al Titular diversa información, ofertas y otros beneficios que pueden resultar de su interés. El Titular podrá revocar dicha autorización según lo establecido en el punto 7.7.</p>
                            <p>(c) Que, frente a un reclamo de un titular, la Empresa utilice la información obrante en su base de datos.</p>
                            <p>2.7.5. La Empresa podrá terminar o suspender el Programa en cualquier momento, notificando dicha decisión con un mínimo de treinta (30) días de anticipación a la fecha de terminación, por cualquier medio masivo de comunicación o su sitio Web.</p>
                            <p>2.7.6. Cualquier comunicación cursada por la Empresa a un Titular se considerará notificada si fue remitida al domicilio o dirección de e-mail del mismo obrante en los registros de los Titulares de la base de datos del Programa.</p>
                            <p>2.7.7. El Cliente, al adherirse al Programa, brinda su conformidad y autoriza a la Empresa a enviarle y trasmitirle todo tipo de comunicaciones, avisos y mensajes que guarden relación con el Programa y con los fines publicitarios y promocionales a los domicilios mencionados precedentemente, como así también a las direcciones de e-mail y teléfonos, que figuren en los registros de los titulares. El Titular podrá revocar dicha autorización manifestando por mail a pedidos@equus.com.ar expresando su deseo de no recibir aquellas comunicaciones.</p>
                            <p>2.7.8. El Programa será válido únicamente en los puntos de venta de los establecimientos adheridos. El Programa podrá ser extendido a otros países.</p>
                            <p>2.7.9. “La Empresa” tiene su domicilio comercial en Capitán Cajaraville nro. 2902, Carapachay, Provincia de Buenos Aires, Argentina.</p>
                            <p>2.7.10. Cualquier exclusión o limitación de responsabilidad contenida en el presente en favor de la Empresa se extiende a cada uno de sus socios y sus personas jurídicas vinculadas. El término "persona jurídica vinculada" tiene el significado dado por la Ley Nº 19.550 de Sociedades Comerciales.</p>
                            <p>2.7.11. En ningún caso la Empresa será responsable, por la utilización indebida que pudieran hacer terceros de las Apps, ni por los daños y perjuicios que tal circunstancia pudiera ocasionar. En este sentido no responderá en caso de malfuncionamiento de la App en el dispositivo móvil del Cliente, ni ningún uso por extraños que utilizaren impropiamente las Apps, o en cualquier otra acción realizada desde la App que contraríe la voluntad de los Titulares.</p>
                            <p>2.7.11. La eventual nulidad de alguna de las cláusulas de los Términos y Condiciones no importará la nulidad de las restantes cláusulas.</p>
                            <p>2.7.13. La Empresa se reserva el derecho de variar los Términos y Condiciones, como así también los Beneficios, en tanto fuera necesario, durante el desarrollo del Programa.</p>
                            <p>2.7.14. La participación en el Programa implica la aceptación de todas las condiciones estipuladas en los presentes Términos y Condiciones, los que se reputan conocidos por los Titulares.</p>
                            <p>2.7.15. Cualquier cuestión que se suscite con el Titular en relación al Programa, será resuelta en forma definitiva e inapelable por la Empresa.</p>
                            <p>2.7.17. El titular fija su domicilio en el informado en la solicitud de adhesión al programa y la Empresa en el informado en el presente, y acuerdan que para cualquier disputa o divergencia derivada de los presentes Términos y Condiciones del Programa, somete la misma a la jurisdicción de los Tribunales Nacionales Ordinarios de la Ciudad Autónoma de Buenos Aires y a la competencia según la materia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({
    auth: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Terms);
