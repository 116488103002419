const assets = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ASSETS':
      state = action.assets;
      return state;
    case 'CLEAN_ASSETS':
      return {};
    default:
      return state;
  };
};

export default assets;