import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import validator from 'validator';
import { listReferrers, cancel } from '../services/referrers'
import { checkEmail, checkNationalIdentity, createUser, verifyEmail } from '../services/user';
import { timeAgo } from '../utils/dates';
import { days, months, years } from '../utils/date-select';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input/input';

const fields = {
    1: ['first_name', 'last_name', 'national_identity', 'birthday', 'phone_number', 'gender', 'city'],
    2: ['email', 'password']
};

const genderValues = [
    {
      value: 'male',
      label: 'Masculino',
    },
    {
      value: 'female',
      label: 'Femenino',
    },
    {
      value: 'other',
      label: 'Otro',
    },
];

const dayOpts = days();
const yearsOpts = years();

const Signup = (props) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState(false);
    const [inputError, setInputError] = useState(null);
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
        gender: '',
        birthday: '',
        city: '',
        password: '',
        national_identity: '',
        referrer: '',
        phone_number: ''
    });
    const [password, setPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [bday, setBday] = useState(null);
    const [bmonth, setBmonth] = useState(null);
    const [byear, setByear] = useState(null);
    const [parsedGender, setParsedGender] = useState('');
    const [referrerValues, setReferrerValues] = useState([]);
    const path = props.location.search.substr(6);

    useEffect(() => {
        document.title = 'EQS CLUB - Creá tu cuenta';
    }, []);

    useEffect(() => {
        const getRefs = async () => {
            const refs = await listReferrers();
            setReferrerValues(refs.map(a => ({ label: a.name, value: a.name })));
        };

        getRefs();

        return () => {
            if (cancel) {
                cancel();
            }
        }
    }, []);

    useEffect(() => {
        // Get referrer
        if (!props.location.search || props.location.search === '') {
            return false;
        }

        const params = props.location.search.replaceAll('?', '&').split('&');
        params.forEach(item => {
            const a = item.split('=');
            if (a[0] === 'ref') {
                setUser(state => ({ ...state, referrer: a[1] }));
            }
        });

    }, [props.location.search]);

    useEffect(() => {
        if (props.auth) {
            if (path) {
                props.history.push(path);
            } else {
                props.history.push('/');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.auth]);

    useEffect(() => {
        if (bday && bmonth && byear) {
            const b = new Date(byear, bmonth, bday);
            if (b.getDate() === parseInt(bday, 10)) {
                setInputError(null);
                setError(null);
                setUser((state) => ({
                    ...state,
                    birthday: b,
                }))
            } else {
                setUser((state) => ({
                    ...state,
                    birthday: '',
                }));
                setInputError('birthday');
                setError('La fecha ingresada no es válida.');
            }
        }
    }, [bday, bmonth, byear]);

    useEffect(() => {
        if (user.gender !== '') {
            for (let i = 0; i < genderValues.length; i++) {
                if (genderValues[i].value === user.gender) {
                    setParsedGender(genderValues[i].label);
                    break;
                }
            }
        }
    }, [user.gender])

    const Submit = async () => {
        try {
            await createUser(user);
        } catch (e) {
            return errorHandler(e.status);
        }
    };

    const errorHandler = (status) => {
        let text = '';
        switch (status) {
            case 0:
                text = 'Por favor, completá tus datos';
                break;
            case 'Authentication error':
                text = 'Por favor, revisa las credenciales ingresadas.';
                break;
            case 'User not found':
                text = 'Por favor, revisa las credenciales ingresadas.';
                break;
            case 500:
                text = 'Servicio no disponible.';
                break;
            default:
                text = 'Hubo un problema. Inténtalo más tarde.'
                break;
        }
        return setError(text);
    };

    const handleInputChanges = (e) => {
        setUser(state => ({
            ...state,
            [e.target.id]: e.target.value,
        }));
    };

    const handlePhoneChange = (e) => {
        setUser(state => ({
            ...state,
            phone_number: e,
        }));
    }

    const handleDateInputChanges = (e) => {
        switch (e.target.id) {
            case 'day':
                setBday(e.target.value);
                break;
            case 'month':
                setBmonth(e.target.value);
                break;
            case 'year':
                setByear(e.target.value);
                break;
            default:
                break;
        }
    };

    const Next = async () => {
        setInputError(null);
        setError(null);

        for (let i in fields[step]) {
            if (!user[fields[step][i]] || user[fields[step][i]] === '' || user[fields[step][i]].length < 2) {
                setInputError(fields[step][i]);
                return setError('Por favor, completá todos los campos.');
            }

            if (fields[step][i] === 'phone_number') {
                if (!isValidPhoneNumber(user.phone_number)) {
                    setInputError(fields[step][i]);
                    return setError('Ingresá un número de teléfono válido');
                }
            }

            if (fields[step][i] === 'national_identity') {
                const cleaned = user.national_identity.replace(/[^0-9]/gi, '');

                if (cleaned === '' || cleaned.length > 10 || cleaned.length < 7) {
                    setInputError(fields[step][i]);
                    return setError('Por favor, ingresá un DNI válido.');
                }

                const dniAvailable = await checkNationalIdentity(cleaned);

                if (!dniAvailable) {
                    setInputError(fields[step][i]);
                    return setError('El DNI ingresado ya se encuentra registrado.');
                }

                setUser(state => ({ ...state, national_identity: cleaned }));
            }

            if (fields[step][i] === 'birthday') {
                const thisYear = new Date().getFullYear();
                const inputYear = new Date(user.birthday).getFullYear();

                if (thisYear <= inputYear || thisYear - inputYear > 114) {
                    setInputError(fields[step][i]);
                    return setError('Por favor, ingresá una fecha de nacimiento válida.');
                }

                if (thisYear - inputYear <= 13) {
                    setInputError(fields[step][i]);
                    return setError('Debes ser mayor de 13 años para crear una cuenta.');
                }
            }

            if (fields[step][i] === 'email') {
                if (!validator.isEmail(user[fields[step][i]])) {
                    setInputError(fields[step][i]);
                    return setError('Por favor, ingresá un email válido.');
                }

                const emailValid = await verifyEmail(user[fields[step][i]]);

                if (!emailValid) {
                    setInputError(fields[step][i]);
                    return setError('No podemos validar tu email.');
                }

                if (!emailValid.data.isValid) {
                    setInputError(fields[step][i]);
                    if (emailValid.data.type === 'typo' && emailValid.data.correctEmail) {
                        return setError(`Hay un error en tu email. Quisiste decir ${emailValid.data.correctEmail}?`);
                    }
                    return setError('Por favor, ingresa un email válido.');
                }

                const emailAvailable = await checkEmail(user[fields[step][i]]);

                if (!emailAvailable) {
                    setInputError(fields[step][i]);
                    return setError('El email ingresado ya se encuentra registrado.');
                }
            }

            if (fields[step][i] === 'password') {
                if (user.password.length < 6) {
                    setInputError(fields[step][i]);
                    return setError('Tu contraseña debe tener al menos 6 caracteres.');
                }
            }
        }

        if (step === 2) {
            // Check Password Matching
            if (user.password !== password) {
                setInputError('repeat-password');
                return setError('Las contraseñas no coinciden.');
            }

            // Check Terms Accepted
            if (!terms) {
                return setError('Debes aceptar los términos y condiciones.');
            }
        }

        if (step < 3) {
            setStep(s => s + 1);
        }
    };

    const Back = () => {
        setStep(s => s - 1);
        setError(null);
    };

    return (
        <div className="signup-template">
            <div className="row g-0 align-items-center">
                <div className="col-lg-7 bg-black">
                    {
                        props.assets.signin_cover_web && <img src={props.assets.signin_cover_web} className="cover-fit" alt="Sign in Cover" />
                    }
                </div>
                <div className="col-lg-5 px-md-3 px-xl-5">
                    <div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
                        <div className="login-form py-2 py-md-0 mx-auto mx-lg-0">
                            <h2 className="h1 mb-3">Creá tu cuenta</h2>
                            <div className="row mb-3">
                                <div className="col-auto">
                                    <div className={`ratio ratio-1x1 rounded-24 p-3 ${step === 1 ? 'bg-primary text-white' : 'bg-white text-black'}`}>
                                        <span className="d-flex justify-content-center align-items-center">1</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className={`ratio ratio-1x1 rounded-24 p-3 ${step === 2 ? 'bg-primary text-white' : 'bg-white text-black'}`}>
                                        <span className="d-flex justify-content-center align-items-center">2</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className={`ratio ratio-1x1 rounded-24 p-3 ${step === 3 ? 'bg-primary text-white' : 'bg-white text-black'}`}>
                                        <span className="d-flex justify-content-center align-items-center">3</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2">
                                {
                                    step === 1 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-6 mb-2 pb-md-2">
                                                <label className="form-label form-label-lg" htmlFor="first_name">Nombre</label>
                                                <input type="text" className={`form-control form-control-xl ${inputError === 'first_name' ? 'is-invalid' : ''}`} id="first_name" placeholder="Nombre" value={user.first_name} onChange={handleInputChanges} />
                                            </div>
                                            <div className="col-md-6 mb-2 pb-md-2">
                                                <label className="form-label form-label-lg" htmlFor="last_name">Apellido</label>
                                                <input type="text" className={`form-control form-control-xl ${inputError === 'last_name' ? 'is-invalid' : ''}`} id="last_name" placeholder="Apellido" value={user.last_name} onChange={handleInputChanges} />
                                            </div>
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="national_identity">Número de Documento</label>
                                            <input type="text" className={`form-control form-control-xl ${inputError === 'national_identity' ? 'is-invalid' : ''}`} id="national_identity" placeholder="Nro. de Documento" value={user.national_identity} onChange={handleInputChanges} />
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="day">Fecha de Nacimiento</label>
                                            <div className="input-group input-group-xl">
                                                <select id="day" className={`form-control form-control-xl ${inputError === 'birthday' ? 'is-invalid' : ''}`} aria-label="Género" onChange={handleDateInputChanges}>
                                                    <option selected disabled>Día</option>
                                                    {
                                                        dayOpts.map(o => <option value={o.value} key={o.value}>{o.label}</option>)
                                                    }
                                                </select>
                                                <span className="input-group-text">/</span>
                                                <select id="month" className={`form-control form-control-xl ${inputError === 'birthday' ? 'is-invalid' : ''}`} aria-label="Género" onChange={handleDateInputChanges}>
                                                    <option selected disabled>Mes</option>
                                                    {
                                                        months.map(o => <option value={o.value} key={o.value}>{o.label}</option>)
                                                    }
                                                </select>
                                                <span className="input-group-text">/</span>
                                                <select id="year" className={`form-control form-control-xl ${inputError === 'birthday' ? 'is-invalid' : ''}`} aria-label="Género" onChange={handleDateInputChanges}>
                                                    <option selected disabled>Año</option>
                                                    {
                                                        yearsOpts.map(o => <option value={o.value} key={o.value}>{o.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="gender">Teléfono</label>
                                            <PhoneInput
                                                country='AR'
                                                placeholder="Numero de telefono"
                                                className={`form-control form-control-xl ${inputError === 'phone_number' ? 'is-invalid' : ''}`}
                                                value={user.phone_number}
                                                onChange={handlePhoneChange}
                                                international
                                            />
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="referrer">¿Cómo te enteraste de EQS Club?</label>
                                            <select id="referrer" className={`form-control form-control-xl ${inputError === 'referrer' ? 'is-invalid' : ''}`} aria-label="Referente" onChange={handleInputChanges}>
                                                <option selected disabled>Seleccionar una opción</option>
                                                {
                                                    referrerValues.map(o => <option value={o.value} key={o.value}>{o.label}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="gender">Género</label>
                                            <select id="gender" className={`form-control form-control-xl ${inputError === 'gender' ? 'is-invalid' : ''}`} aria-label="Género" onChange={handleInputChanges}>
                                                <option selected disabled>Seleccionar una opción</option>
                                                {
                                                    genderValues.map(o => <option value={o.value} key={o.value}>{o.label}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="city">Ciudad</label>
                                            <input type="text" className={`form-control form-control-xl ${inputError === 'city' ? 'is-invalid' : ''}`} id="city" placeholder="Ciudad" value={user.city} onChange={handleInputChanges} />
                                        </div>
                                        <div className="d-grid">
                                            <p className="text-danger fw-bold">{error}</p>
                                            <button type="button" className="btn btn-xl btn-primary" onClick={Next}>Siguiente</button>
                                        </div>
                                    </>
                                }
                                {
                                    step === 2 &&
                                    <>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="email">Email</label>
                                            <input type="email" className={`form-control form-control-xl ${inputError === 'email' ? 'is-invalid' : ''}`} id="email" placeholder="Email" value={user.email} onChange={handleInputChanges} />
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="Password">Contraseña</label>
                                            <input type="password" className={`form-control form-control-xl ${inputError === 'password' ? 'is-invalid' : ''}`} id="password" placeholder="Contraseña" value={user.password} onChange={handleInputChanges} />
                                        </div>
                                        <div className="mb-2 pb-md-2">
                                            <label className="form-label form-label-lg" htmlFor="repeat-password">Repetí la contraseña</label>
                                            <input type="password" className={`form-control form-control-xl ${inputError === 'repeat-password' ? 'is-invalid' : ''}`} id="repeat-password" placeholder="Repetí la contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={terms} onChange={(e) => setTerms(e.target.checked)} />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Acepto los <NavLink to="/terminos-y-condiciones" className="fw-bold" target='_blank'>términos y condiciones.</NavLink></label>
                                        </div>
                                        <div className="d-grid">
                                            <p className="text-danger fw-bold">{error}</p>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="button" onClick={Back} className="btn btn-xl btn-outline-dark w-100">Volver</button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="button" onClick={Next} className="btn btn-xl btn-primary w-100">Siguiente</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    step === 3 &&
                                    <>
                                        <div>
                                            <h6 className="mt-3 mb-3">Por favor, revisa los datos ingresados:</h6>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticName" className="col-sm-3 col-form-label fw-bold">Nombre</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext text-capitalize" id="staticName" value={`${user.first_name} ${user.last_name}`} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticDni" className="col-sm-3 col-form-label fw-bold">DNI</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext" id="staticDni" value={user.national_identity} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticBirthday" className="col-sm-3 col-form-label fw-bold">Nacimiento</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext" id="staticBirthday" value={timeAgo(user.birthday)} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticPhone" className="col-sm-3 col-form-label fw-bold">Teléfono</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext" id="staticPhone" value={formatPhoneNumberIntl(user.phone_number)} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticGender" className="col-sm-3 col-form-label fw-bold">Género</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext" id="staticGender" value={parsedGender} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticCity" className="col-sm-3 col-form-label fw-bold">Ciudad</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext text-capitalize" id="staticCity" value={user.city} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label fw-bold">Email</label>
                                                <div className="col-sm-9">
                                                    <input type="text" readonly="" className="form-control-plaintext" id="staticEmail" value={user.email} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-grid">
                                            <p className="text-danger fw-bold">{error}</p>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="button" onClick={Back} className="btn btn-xl btn-outline-dark w-100">Volver</button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="button" onClick={Submit} className="btn btn-xl btn-primary w-100">Crear Cuenta</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="border-top border-gray-200 pt-4">
                                    <span className="text-gray-700">¿Ya tenés una cuenta? <NavLink to="/ingresa" className="link-primary">Ingresá</NavLink></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.auth.isAuthenticated,
    assets: state.assets,
});

export default connect(mapStateToProps)(Signup);