// import $ from 'jquery';

const initialState = { loading: false, loader: 'FULLSCREEN' };

const loading = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      state = {
        loading: true
      }
      const element = document.querySelector("body")
      element.classList.add("body-loading")
      return state;
    case 'LOADED':
      state = {
        loading: false
      }
      const el = document.querySelector("body")
      el.classList.remove("body-loading")
      return state;
    default:
      return state;
  };
};

export default loading;