import axios from 'axios';
const CancelToken = axios.CancelToken;
export let cancel;

export const listReferrers = async () => {
  try {
    const request = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/referrers/list/${process.env.REACT_APP_BRAND}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      },
    );

    return request.data;
  } catch (err) {
    throw err;
  }
};
